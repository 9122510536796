import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import Person from "../components/person-card";
import WorkCta from "../components/cta/cta_work-with-us";

import styles from "../scss/layouts/about.module.scss";
import HeroBanner from "../components/page-hero-banner";

export default ({ data }) => {
  const pageData = data.markdownRemark;
  const people = data.allMarkdownRemark;
  const heroImageSources = [
    data.largeHero.childImageSharp.fluid,
    {
      ...data.smallHero.childImageSharp.fluid,
      media: `(max-width: 800px)`,
    },
  ]
  return (
    <Layout>
      <SEO
        title={pageData.frontmatter.title}
        description={pageData.frontmatter.intro }
        pathname={pageData.fields.slug}
      />

      <HeroBanner imageSources={heroImageSources}/>
      <div className={styles.about_content}>
        <div className={styles.about_content__inner}>
          <div className={styles.about_content__columns} dangerouslySetInnerHTML={{ __html: pageData.html }} />
        </div>
      </div>

      <div className={styles.sub_heading}>
        <h2>Meet the team</h2>
      </div>

      <div className={styles.team_wrapper}>
      { people.nodes.map(function(person, index) {
        const sources = [
          person.frontmatter.tiledImage.childImageSharp.fluid,
          {
            ...person.frontmatter.featuredImage.childImageSharp.fluid,
            media: `(max-width: 800px)`,
          },
        ]

        return <Person key={ index }
                       name={ person.frontmatter.name }
                       job={ person.frontmatter.job_title }
                       qualifications={ person.frontmatter.qualifications }
                       content={ person.html }
                       imageSources={ sources }/>;
      }) }
      </div>
      <WorkCta />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        template
        intro
      }
      html
      fields {
        slug
      }
    }
    largeHero:file(relativePath: {eq: "hero_banners/about_us@2x.jpg"}) {
      id
      childImageSharp {
        fluid(maxWidth: 3000, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    smallHero:file(relativePath: {eq: "hero_banners/about_us.jpg"}) {
      id
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/team/"}
      }
      sort: {
        fields: frontmatter___rank,
        order: DESC
      }) {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          name
          job_title
          qualifications
          tiledImage {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
