import React from "react";
import Image from "gatsby-image";

import styles from "../scss/modules/page_banners.module.scss";

export default ( props ) => {
  let introBox = <div className={styles.page_hero_banner__intro}>
    <h1>{props.title}</h1>
    <div className={styles.page_hero_banner__introcopy} dangerouslySetInnerHTML={{ __html: props.introduction }} />
  </div>

  if ( !props.title && !props.introduction ) {
    introBox = '';
  }
  return (
    <div className={styles.page_hero_banner__wrapper}>
      <section className={styles.page_hero_banner}>
        <div className={styles.page_hero_banner__inner}>
          <div className={styles.page_hero_banner__image}>
            <Image fluid={props.imageSources} />
          </div>
          {introBox}
        </div>
      </section>
    </div>
  )
}
