import React from "react"
import Img from "gatsby-image"

import styles from "../scss/modules/person_card.module.scss"

export default ( props ) => {
  return (
    <div className={styles.person_card} itemScope itemType="http://schema.org/Person">
      <div className={styles.person_card__image_wrapper}>
        <Img fluid={props.imageSources} />
      </div>
      <h2 className={styles.person_card__name}>{props.name}</h2>
      <p className={styles.person_card__qualifications}><span itemScope itemType="http://schema.org/Occupaton"
               itemProp="qualifications">{props.qualifications}</span> - <span itemProp="jobTitle">{props.job}</span>
      </p>
      <div itemProp="description" className={styles.person_card__bio} dangerouslySetInnerHTML={{__html: props.content}}/>
    </div>
  )
}
